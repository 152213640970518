.search-agent {
  position: relative;
  border: 1px solid #eee;
  background: #fff;

  &--header {
    padding: 6px 10px 4px;
    background: rgba(0,0,0,.05);
    border-bottom: 1px solid #eee;
    color: #666;
    line-height: 26px;

    h3 {
      display: inline;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    a {
      float: right;
      font-size: 12px;
      color: #666;
    }
  }

  .search-result-item {
    cursor: pointer;
    padding: 10px;

    &:hover {
      background: rgba(0,0,0,.05);
    }

    h4 {
      display: inline;
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 5px 0;
    }

    &--description {
      display: inline;
      opacity: .75;
      font-size: 14px;
      &:before {
        content: ', ';
      }
    }
  }

  &--empty {
    display: block;
    padding: 10px;
  }

  .loading {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
