.layer-group--list-item
 {
  margin-top: 9px;
  margin-bottom: 4px;
  user-select: none;
}


.layer-group--list-item-icon {
 display: inline-block;

}

.layer-group--header {
  line-height: 22px;
  vertical-align: middle;
}

.layer-group--header > .icon  {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.layer-group--header > .icon > svg  {
  position: absolute;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.layer-group--list-item > .icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 6px;
}


.layer-group--list-item-container{
  position: relative;
  cursor: pointer;
  margin: 5px;
}

