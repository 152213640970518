.react-openlayers--map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.react-openlayers--overlay {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
  .react-openlayers--overlay > * {
  	pointer-events: all;
  }


.ol-attribution ul { font-size: 10px; }
.ol-attribution img { display: none; }

.react-openlayers--show-location {
  top: calc(.5em + 74px);
  left: .5em;
}

.ol-unselectable.ol-control > button {
  width: 32px;
  height: 32px;
  font-size: 22px;
  margin-top: 2px;
}

.icon-location {
  width: 16px;
  height: 22px;
  fill:#fff;
}
