.bp3-fill .bp3-popover-target {
  width: 100%;
}
.bp3-label {
  color: #333;
}
.bp3-form-group.bp3-inline {
  margin-bottom: 5px;
}
span.dt-field-value {
  display: inline-block;
}
.dt-field-value {
  padding: 6px 0;
}
