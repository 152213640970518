@keyframes pulsate-dot {
  0% { opacity: 0; transform: scale(.5); }
  50% { opacity: 1;  transform: scale(1); }
  100% { opacity: 0; transform: scale(.5); }
}

.loading {
  width: 20px;
  height: 20px;
  line-height: 4px;

  span {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 1px 1px 0 0;
    border-radius: 10px;
    background: #999;
    opacity: 0;
    animation: pulsate-dot 1.2s infinite ease-in-out;
  }

  span:nth-child(1) {
    animation-delay: 300ms;
  }
  span:nth-child(2) {
    animation-delay: 150ms;
  }
  span:nth-child(3) {
    animation-delay: 200ms;
  }
  span:nth-child(4) {
    animation-delay: 100ms;
  }
  span:nth-child(5) {
    animation-delay: 0ms;
  }
  span:nth-child(6) {
    animation-delay: 100ms;
  }
  span:nth-child(7) {
    animation-delay: 200ms;
  }
  span:nth-child(8) {
    animation-delay: 150ms;
  }
  span:nth-child(9) {
    animation-delay: 300ms;
  }
}
