.search-field {
  &, & * { box-sizing: border-box; }

  position: relative;

  &.showResults {
    z-index: 1;
  }

  &--agents {
    position: absolute;
    max-height: calc(100vh - 100px);
    width: 100%;
    overflow: auto;
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
  }

  &--field {
    position: relative;

    input {
      width: 100%;
      padding: 10px;
      font-size: 100%;
      border: 1px solid #ccc;
    }
  }

  &--clear {
    background: none;
    border: none;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translate(0, -50%);
    padding: 18px 12px;
    cursor: pointer;
    &:hover {
      background: rgba(0,0,0,.05);
    }

    span {
      display: block;
      width: 15px;
      height: 2px;
      border-radius: 2px;
      background: #999;
      margin: -2px;

      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
}
