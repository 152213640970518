

/*
* Layer switcher styles
*/
.layer-switch--container {
  position: absolute;
  background-color: rgba(0,60,136,0.5);
  color: white;
  border-radius: 4px;
  padding: 10px;
  top: calc(.5em + 114px);
  left: calc(.5em + 42px);
  overflow: hidden;
}

.hidden { display:none; }

.layer-switch--button > svg {
  width: 16px;
  height: 22px;
  fill: #fff;
}

.layer-switch--button {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  height: 32px;
  width: 32px;
  line-height: .4em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
}

.layer-switch--button-container {
  position: absolute;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
  top: calc(.5em + 112px);
  left: .5em;
}
