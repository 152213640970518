.zoom-control--root {
  position: absolute;
  top: 10px;
  left: 10px;
}

.zoom-control--root > button {
  position: relative;
  display: block;
  background: rgba(0, 60, 136, 0.5);
  height: 32px;
  width: 32px;
  color: #fff;
  border: none;
  margin: 1px;
  border-radius: 2px;
}

.zoom-control--root > button > svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  height: 14px;
  width: 14px;
  fill:currentColor;
}
